import React from "react";
// import { useLocation } from "react-router-dom";

const Footer: React.FC = () => {
  // const { pathname } = useLocation();

  return (
    <div className="footer">
      <div className="firstRow">
        <img
          className="logoImage"
          src="../images/homePage/logo.png"
          alt="Metaversium"
        />
        <div className="getStartedContainer">
          <p className="headerText">Have more questions?</p>
          <div className="btnContainer">
            <a
              href="https://metaversium.gitbook.io/docs/"
              target="_blank"
              rel="noreferrer"
            >
              <span className="btnText">Documentation</span>
            </a>
          </div>
        </div>
      </div>
      <div className="divider" />
      {/* <div className="secondRow">
        <div className="rowWrapper">
          <span className="subHeaderText">
            Subscribe to our
            <br />
            newsletter
          </span>
          <div className="emailSubContainer">
            <input placeholder="Email address" className="subInput" />
            <div className="subBtn">
              <img src="../images/homePage/arrowRightIcon.png" alt="Arrow Right" />
            </div>
          </div>
        </div>
        <div className="internalLinksContainer">
          <div className="firstColumn">
            <span>Services</span>
            <p>Email Marketing</p>
            <p>Campaigns</p>
            <p>Branding</p>
            <p>Offline</p>
          </div>
          <div className="secondColumn">
            <span>About</span>
            <p>Our Story</p>
            <p>Benefits</p>
            <p>Team</p>
            <p>Careers</p>
          </div>
          <div>
            <span>Help</span>
            <p>FAQs</p>
            <p>Contact Us</p>
          </div>
        </div>
      </div> */}
      <div className="thirdRow">
        <p className="subHeaderText">
          Metaversium &copy; 2023, All rights reserved
        </p>
        <div className="socials">
          <a
            className="lnk"
            href="https://twitter.com/metaversiumgame"
            target="_blank"
            rel="noreferrer"
          >
            <span className="fab fa-twitter" />
          </a>
          <a
            className="lnk"
            href="https://discord.com/invite/Mr24MZh8q3"
            target="_blank"
            rel="noreferrer"
          >
            <span className="fab fa-discord" />
          </a>
          <a
            className="lnk"
            href="https://www.youtube.com/channel/UCcWzKfykr42xlhChDEqcVQw"
            target="_blank"
            rel="noreferrer"
          >
            <span className="fab fa-youtube" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
