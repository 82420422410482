import React from 'react'

interface PictureProps {
  path: string;
  imageName: string;
  alt: string;
  className?: string;
  style?: React.CSSProperties;
}

const Picture = ({ path, imageName, alt, className = '', style={} }: PictureProps) => {
  return (
    <picture>
      <source type="image/webp" srcSet={`${path}/webp/${imageName.split('.')[0]}.webp`} />
      <source type="image/avif" srcSet={`${path}/avif/${imageName.split('.')[0]}.avif`} />
      <img className={className} style={style} alt={alt} src={`${path}/${imageName}`} />
    </picture>
  )
}

export default Picture
