import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { initLocalization } from "./localization";
import { Footer } from "./components";
import { HomePage } from "./pages";

initLocalization();

const App = () => {

  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
