import React, { useState } from "react";

import { Header } from "../components";
import ROADMAP from "../roadmap.json";
import Picture from "components/ui/Picture";

const HomePage: React.FC = () => {
  const [level, setLevel] = useState(1);

  return (
    <div>
      <div
        className="background1Wrapper"
        style={{ backgroundImage: "url(../images/homePage/webp/background1.webp)" }}
      >
        <Header />
        <div className="block1">
          <div className="leftTextContainer">
            <p className="boldText">Fully on-chain</p>
            <p className="outlinedText">4X, city-building</p>
            <p className="boldText">strategy game</p>
            <p className="subHeaderText">
              Metaversium is the strategy game of exploration, territorial
              expansion, resource farming and PvP battles powered by DeFi,
              Solana blockchain and NFTs.
            </p>
            <div className="pinkButton">
              <a href="https://metaversium.io/onepager.html">
                <span className="pinkButtonText">One Pager</span>
              </a>
            </div>
          </div>
          <div className="rightContainer">
            <Picture
              path="../images/homePage"
              imageName="manAndWoman.png"
              alt="Metaversium"
              className="animatedPreview"
            />
          </div>
        </div>
      </div>
      <div className="block2">
        <div className="central">
          <span className="subHeaderText">
            100% of assets are NFTs, semi-fungible or fungible tokens owned by
            players. 💯
          </span>
          <div className="imagePreviews">
            <div className="imageItem hideOnMobile">
              <img src="../images/land-preview-1.jpeg" alt="" />
            </div>
            <div className="imageItem hideOnMobile">
              <img src="../images/land-preview-2.jpeg" alt="" />
            </div>
            <div className="imageItem hideOnMobile">
              <img src="../images/land-preview-3.jpeg" alt="" />
            </div>
            <div className="imageItem hideOnMobile">
              <img src="../images/land-preview-5.jpeg" alt="" />
            </div>
            <div className="imageItem hideOnMobile">
              <img src="../images/land-preview-4.jpeg" alt="" />
            </div>
          </div>
          <div className="imagePreviews">
            <div className="imageItem hideOnMobile">
              <img src="../images/worker-1.png" alt="" />
            </div>
            <div className="imageItem hideOnMobile">
              <img src="../images/worker-2.png" alt="" />
            </div>
            <div className="imageItem hideOnMobile">
              <img src="../images/worker-3.png" alt="" />
            </div>
            <div className="imageItem hideOnMobile">
              <img src="../images/worker-4.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fill="#1a1c21"
            fillOpacity="1"
            d="M0,224L40,240C80,256,160,288,240,272C320,256,400,192,480,176C560,160,640,192,720,213.3C800,235,880,245,960,224C1040,203,1120,149,1200,138.7C1280,128,1360,160,1400,176L1440,192L1440,0L1400,0C1360,0,1280,0,1200,0C1120,0,1040,0,960,0C880,0,800,0,720,0C640,0,560,0,480,0C400,0,320,0,240,0C160,0,80,0,40,0L0,0Z"
          ></path>
        </svg>
      </div>
      <div className="blockVideo">
        <div className="headerContainer">
          <span className="headerText">
            Watch The Trailer <span className="hideOnMobile">🔥</span>
          </span>
          <div className="videoContainer">
            <div className="youtubeContainer">
              <iframe
                width="960"
                height="540"
                src="https://www.youtube.com/embed/5tvQ1AJXg8w"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              />
            </div>
          </div>
        </div>
      </div>
      <div className="block3">
        <div className="headerContainer">
          <span className="headerText">
            Building blocks
            <br />
          </span>
          <span className="subHeaderText">of Metaversium`s economy 🤑</span>
        </div>
        <div className="buildingBlocks">
          <div className="buildingBlock">
            <div className="imageContainer">
              <Picture
                path="../images/homePage"
                imageName="manWithVr.png"
                alt="Main with VR"
              />
            </div>
            <span className="headerText">NFT Characters</span>
            <p className="subHeaderText">
              Governors are required to dispatch armies on various tasks,
              including gathering resources, exploring new territories, and
              engaging in combat with other players.
            </p>
          </div>
          <div className="buildingBlock centralBuildingBlock">
            <div className="imageContainer">
              <Picture
                path="../images/homePage"
                imageName="wood.png"
                alt="Wood"
              />
            </div>
            <span className="headerText">Resource farming</span>
            <p className="subHeaderText">
              Resources like $WOOD, $STONE, $IRON and $WHEAT are fungible
              utility tokens that can be earned and utilized within the game or
              transferred to a personal wallet.
            </p>
          </div>
          <div className="buildingBlock">
            <div className="imageContainer">
              <Picture
                path="../images/homePage"
                imageName="sawmill1.png"
                alt="Metaversium: Timber Camp"
                style={{ maxWidth: "240px" }}
              />
            </div>
            <span className="headerText">Constructions</span>
            <p className="subHeaderText">
              Each plot of land within the game is a NFT that can be explored on
              various missions, minted with resources, and upgraded to enhance
              yields or to gain access to new gameplay mechanics.
            </p>
          </div>
        </div>
        <div className="landBuildingBlock">
          <Picture
            path="../images"
            imageName="barracks-lvl2.png"
            alt="Metaversium: barracks"
            style={{ maxWidth: "360px" }}
          />
          <div style={{ paddingLeft: "40px" }}>
            <span className="headerText">On-chain PvE and PvP Battles</span>
            <p className="subHeaderText">
              Players can raid other settlements and loot resources that have
              been left unclaimed. Units in the game are semi-fungible tokens
              that may be lost during the battle. To recruit soldiers, players
              must acquire a Barracks NFT and hire units through a swap of
              resources, such as bread, beer, and iron, at a set price.
            </p>
          </div>
        </div>
      </div>
      <div
        className="background2Wrapper"
        style={{ backgroundImage: "url(../images/homePage/webp/background2.webp)" }}
      >
        <div className="block4">
          <Picture
            alt="Lands with circles"
            path="../images/homePage"
            imageName="landOwnership.png"
          />
          <div className="landOwnerShip">
            <span className="headerText">Land ownership</span>
            <p className="subHeaderText">
              As landowners, players will receive a revenue in $META,
              <br />
              our in-game currency. More details will be revealed soon.
            </p>
            <p className="landOwnerShipText">
              Additionally, landowners have the ability to establish a tax of up
              to 10% on all resources gathered on their land plots. This tax is
              imposed on players who are harvesting resources on land that does
              not belong to them.
            </p>
          </div>
        </div>
        <Picture
          path="../images/homePage"
          imageName="metaBigText.png"
          alt="Metaversium"
        />
        <div className="block5">
          <div className="headerTextContainer">
            <div className="internalTextContainer">
              <span className="headerText">Upgradable buildings</span>
              <p className="subHeaderText">
                Players can earn different in-game resources by staking their
                building NFTs.
              </p>
            </div>
            <div>
              <p className="subHeaderPink">
                Buildings can be upgraded to maximize the return from resource
                farming.
              </p>
            </div>
          </div>
          <div className="buildingLevels">
            <div className="levelsImages">
              <div className="rowButtons">
                <div
                  className={
                    level === 1 ? "levelButton activeLevel" : "levelButton"
                  }
                  onClick={() => setLevel(1)}
                  aria-hidden="true"
                >
                  <span className="levelText">Level 1</span>
                </div>
                <div
                  className={
                    level === 2 ? "levelButton activeLevel" : "levelButton"
                  }
                  onClick={() => setLevel(2)}
                  aria-hidden="true"
                >
                  <span className="levelText">Level 2</span>
                </div>
                <div
                  className={
                    level === 3 ? "levelButton activeLevel" : "levelButton"
                  }
                  onClick={() => setLevel(3)}
                  aria-hidden="true"
                >
                  <span className="levelText">Level 3</span>
                </div>
              </div>
              <Picture
                path="../images/homePage"
                imageName={`sawmill${level}.png`}
                alt="Sawmill"
                className="sawmillImage"
              />
            </div>
            <div className="buildingRequirements">
              <div className="buildLevelText">
                <div className="trophyIcon">
                  <img
                    src="../images/homePage/trophyIcon.png"
                    alt="Trophy Icon"
                  />
                </div>
                <div>
                  <span className="subHeaderText">{`Level: ${level}`}</span>
                  <p className="orangeText">Timber Camp</p>
                </div>
              </div>
              <div className="divider" />
              <p className="constCostText">Construction cost:</p>
              <div>
                <div className="constResources">
                  <div className="constResource">
                    <Picture
                      path="../images/homePage"
                      imageName="smallWood.png"
                      alt="Wood"
                    />
                    <p className="grayText">
                      $WOOD:
                      <span className="whiteText">{` ${47 * level}`}</span>
                    </p>
                  </div>
                  <div className="constResource stoneResource">
                    <Picture
                      path="../images/homePage"
                      imageName="smallStone.png"
                      alt="Stone"
                    />
                    <p className="grayText">
                      $STONE:
                      <span className="whiteText">{` ${47 * level}`}</span>
                    </p>
                  </div>
                  <div className="constResource">
                    <Picture
                      path="../images/homePage"
                      imageName="smallIron.png"
                      alt="Iron"
                    />
                    <p className="grayText">
                      $IRON:
                      <span className="whiteText">{` ${47 * level}`}</span>
                    </p>
                  </div>
                </div>
                <div className="pinkButton">
                  <span className="btnText">{`+${
                    level * 6
                  } $WOOD per hour`}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="marketPlaceScreen">
          <div>
            <span className="headerText">Marketplace 🤑</span>
            <p className="subHeaderText">
              All in-game assets can be transferred to a personal Solana wallet
              and traded on the in-game market.
            </p>
          </div>
        </div>
      </div>
      <Picture
        path="../images/homePage"
        imageName="marketPlaceScreen.png"
        alt="Marketplace Screenshot"
        className="marketPlaceImage"
      />
      <div
        className="background3Wrapper roadmapWrapper"
        style={{ backgroundImage: "url(../images/homePage/webp/background3.webp)" }}
      >
        <p className="mainHeaderText">Our mid-term Roadmap! 😎</p>
        <div className="roadmap">
          {ROADMAP.map((item, index) => {
            return (
              <div key={index} className="roadmapItem">
                <div className="roadmapImage">
                  <Picture
                    path="../images/homePage"
                    imageName={item.image}
                    alt=""
                  />
                </div>
                <p className="roadmapDate">{item.title}</p>
                {item.items.map((roadmapItem, index) => {
                  return (
                    <div key={index} className="roadmapTextItem">
                      <div className="checkContainer">
                        {roadmapItem.done ? "✅" : "⚪"}
                      </div>
                      <span className="roadmapText">{roadmapItem.text}</span>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>

        <div className="block7">
          <div
            className="linksContainer"
            style={{
              backgroundImage: "url(../images/homePage/webp/pinkBackground.webp)",
            }}
          >
            <div className="textContainer">
              <p className="headerText">Ready to start the journey?</p>
              <p className="subHeaderText">
                Become a part of our expanding community and stay informed with
                the latest news on Discord or Twitter.
              </p>
            </div>
            <div className="btnContainer">
              <a
                className="btnText"
                href="https://discord.com/invite/Mr24MZh8q3"
              >
                Join our Discord
              </a>
              <a className="btnText" href="https://twitter.com/metaversiumgame">
                Follow in Twitter
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
