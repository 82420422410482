import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "./translations/en.json";

export function initLocalization() {
  i18n.use(initReactI18next).init({
    resources: {
      en: {
        translation: en,
      },
    },
    lng: "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });
}

export function translate(text: string): string {
  if (!text) {
    return "";
  }
  return i18n.t(text);
}

export function formatTranslate(text: string, values: Record<string, string>) {
  if (!text) {
    return "";
  }
  return i18n.t(text, values);
}
