import React, { useState } from "react";
import { Link } from "react-router-dom";

const Header: React.FC = () => {
  const [isMobMenuVis, setIsMobMenuVis] = useState(false);

  return (
    <div className="headerWrapper">
      <div className="header">
        <Link to="/">
          <img
            src="../images/logo.png"
            alt="Metaversium: fully on-chain strategy game"
            width="165"
          />
        </Link>
        <div
          tabIndex={0}
          role="button"
          className="hamburger"
          onClick={() => setIsMobMenuVis(!isMobMenuVis)}
        >
          {isMobMenuVis ? (
            <img src="../images/homePage/closeIcon.png" alt="Close Icon" />
          ) : (
            <img
              src="../images/homePage/hamburgerIcon.png"
              alt="Hamburger Icon"
            />
          )}
        </div>
        <div className="menuItems">
          <a
            href="https://metaversium.gitbook.io/docs/"
            target="_blank"
            rel="noreferrer"
            className="menuItem"
          >
            <p className="menuText">Documentation</p>
          </a>
          <a
            href="https://play.metaversium.io/"
            target="_blank"
            rel="noreferrer"
            style={{ paddingRight: "20px" }}
          >
            <p className="btnContainer">
              <span className="btnText">Metaversium</span>
            </p>
          </a>
          <a
            href="https://aptos.metaversium.io/"
            target="_blank"
            rel="noreferrer"
          >
            <p className="btnContainer">
              <span className="btnText">Astro Clans Demo</span>
            </p>
          </a>
        </div>
      </div>
      {isMobMenuVis && (
        <div className="mobMenuItems">
          <div className="divider" />
          <a
            href="https://metaversium.gitbook.io/docs/"
            target="_blank"
            rel="noreferrer"
            className="menuItem"
          >
            <p className="menuText">Documentation</p>
          </a>
          <div className="divider" />
          <a
            href="https://play.metaversium.io/"
            target="_blank"
            rel="noreferrer"
            className="menuItem"
          >
            <p className="menuText">Metaversium</p>
          </a>
          <div className="divider" />
          <a
            href="https://aptos.metaversium.io/"
            target="_blank"
            rel="noreferrer"
            className="menuItem"
          >
            <p className="menuText">Astro Clans Demo</p>
          </a>
        </div>
      )}
    </div>
  );
};

export default Header;
